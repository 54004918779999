import React from 'react';
import { render } from "react-dom"
import { BrowserRouter, Route } from "react-router-dom"
import Test from "./Test"
import WalletContext from "./WalletContext"


class App extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%"
        }}
      >
        <Route path="/" exact component={Test} />
      </div>
    )
  }
}


render(
  <BrowserRouter>
    <WalletContext>
      <App />
    </WalletContext>
  </BrowserRouter>,
  document.getElementById("root")
)
