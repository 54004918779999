import BN from "bn.js";
import { PublicKey } from "@solana/web3.js";
import * as SPLToken from "@solana/spl-token";
import * as MPLAuctionHouse from "@metaplex-foundation/mpl-auction-house";
import { TransactionBlock, Amount } from "@captainxyz/solana-core";

import { PDA } from "./pda";

export namespace Transactions {
  export function createAuctionHouse(params: {
    owner: PublicKey;
    payer?: PublicKey;
    address?: PDA;
    treasuryMint?: PublicKey;
    tokenProgramId?: PublicKey;
  }): TransactionBlock {
    params.treasuryMint ??= SPLToken.NATIVE_MINT;
    params.tokenProgramId ??= SPLToken.TOKEN_PROGRAM_ID;
    params.payer ??= params.owner;
    params.address ??= PDA.auctionHouse(params.owner, params.treasuryMint);

    const treasuryWithdrawalDestination = params.treasuryMint.equals(
      SPLToken.NATIVE_MINT
    )
      ? params.owner
      : PDA.token(params.treasuryMint, params.owner, params.tokenProgramId);
    const auctionHouseFeeAccount = PDA.feeAccount(params.address);
    const auctionHouseTreasury = PDA.treasury(params.address);

    return {
      allocate: [params.address],
      instructions: [
        MPLAuctionHouse.createCreateAuctionHouseInstruction(
          {
            treasuryMint: params.treasuryMint,
            payer: params.payer,
            authority: params.owner,
            feeWithdrawalDestination: params.owner,
            treasuryWithdrawalDestinationOwner: params.owner,
            treasuryWithdrawalDestination,
            auctionHouse: params.address,
            auctionHouseFeeAccount,
            auctionHouseTreasury,
            tokenProgram: params.tokenProgramId,
          },
          {
            bump: params.address.bump,
            feePayerBump: auctionHouseFeeAccount.bump,
            treasuryBump: auctionHouseTreasury.bump,
            sellerFeeBasisPoints: 0,
            requiresSignOff: false,
            canChangeSalePrice: false,
          }
        ),
      ],
    };
  }

  export function listNFTForSale(params: {
    seller: PublicKey;
    auctionHouse: {
      address: PublicKey;
      authority: PublicKey;
      treasuryMint?: PublicKey;
      feeAccount?: PublicKey;
    };
    nft: {
      mint: PublicKey;
      address?: PublicKey;
      metadata?: PublicKey;
    };
    price: Amount;
  }): TransactionBlock {
    const { seller, auctionHouse, nft, price } = params;

    nft.address ??= PDA.token(nft.mint, seller);
    nft.metadata ??= PDA.tokenMetadata(nft.mint);

    auctionHouse.treasuryMint ??= SPLToken.NATIVE_MINT;
    auctionHouse.feeAccount ??= PDA.feeAccount(auctionHouse.address);

    const sellerTradeState = PDA.tradeState(
      auctionHouse.address,
      auctionHouse.treasuryMint,
      seller,
      nft.address,
      nft.mint,
      price.qty,
      1n
    );
    const freeSellerTradeState = PDA.tradeState(
      auctionHouse.address,
      auctionHouse.treasuryMint,
      seller,
      nft.address,
      nft.mint,
      0n,
      1n
    );

    return {
      instructions: [
        MPLAuctionHouse.createSellInstruction(
          {
            wallet: params.seller,
            tokenAccount: nft.address,
            metadata: nft.metadata,
            authority: auctionHouse.authority,
            auctionHouse: auctionHouse.address,
            auctionHouseFeeAccount: auctionHouse.feeAccount,
            sellerTradeState,
            freeSellerTradeState,
            programAsSigner: PDA.PROGRAM_AS_SIGNER,
          },
          {
            tradeStateBump: sellerTradeState.bump,
            freeTradeStateBump: freeSellerTradeState.bump,
            programAsSignerBump: PDA.PROGRAM_AS_SIGNER.bump,
            buyerPrice: new BN(price.qty.toString()),
            tokenSize: 1,
          }
        ),
      ],
    };
  }

  export function buyNFT(params: {
    buyer: PublicKey;
    seller: PublicKey;
    auctionHouse: {
      address: PublicKey;
      authority: PublicKey;
      treasuryMint?: PublicKey;
      treasury?: PublicKey;
      feeAccount?: PublicKey;
    };
    nft: {
      mint: PublicKey;
      address?: PublicKey;
      metadata?: PublicKey;
      toAddress?: PublicKey;
      creators?: PublicKey[];
    };
    price: Amount;
  }): TransactionBlock {
    const { buyer, seller, auctionHouse, nft, price } = params;

    nft.address ??= PDA.token(nft.mint, seller);
    nft.toAddress ??= PDA.token(nft.mint, buyer);
    nft.metadata ??= PDA.tokenMetadata(nft.mint);
    nft.creators ??= [];

    auctionHouse.treasuryMint ??= SPLToken.NATIVE_MINT;
    auctionHouse.treasury ??= PDA.treasury(auctionHouse.address);
    auctionHouse.feeAccount ??= PDA.feeAccount(auctionHouse.address);

    const isNative = auctionHouse.treasuryMint.equals(SPLToken.NATIVE_MINT);

    const buyerEscrow = PDA.buyerEscrow(auctionHouse.address, buyer);
    const buyerTradeState = PDA.tradeState(
      auctionHouse.address,
      auctionHouse.treasuryMint,
      buyer,
      nft.address,
      nft.mint,
      price.qty,
      1n
    );
    const sellerTradeState = PDA.tradeState(
      auctionHouse.address,
      auctionHouse.treasuryMint,
      seller,
      nft.address,
      nft.mint,
      price.qty,
      1n
    );
    const freeSellerTradeState = PDA.tradeState(
      auctionHouse.address,
      auctionHouse.treasuryMint,
      seller,
      nft.address,
      nft.mint,
      0n,
      1n
    );

    const srcPaymentAccount = isNative
      ? buyer
      : PDA.token(auctionHouse.treasuryMint, buyer);
    const dstPaymentAccount = isNative
      ? seller
      : PDA.token(auctionHouse.treasuryMint, seller);

    const creators = [];
    for (const creator of nft.creators) {
      creators.push({
        pubkey: creator,
        isWritable: true,
        isSigner: false,
      });
      if (!isNative) {
        creators.push({
          pubkey: PDA.token(auctionHouse.treasuryMint, creator),
          isWritable: true,
          isSigner: false,
        });
      }
    }

    return {
      instructions: [
        MPLAuctionHouse.createBuyInstruction(
          {
            wallet: buyer,
            paymentAccount: srcPaymentAccount,
            transferAuthority: buyer,
            tokenAccount: nft.address,
            metadata: nft.metadata,
            escrowPaymentAccount: buyerEscrow,
            authority: auctionHouse.authority,
            treasuryMint: auctionHouse.treasuryMint,
            auctionHouse: auctionHouse.address,
            auctionHouseFeeAccount: auctionHouse.feeAccount,
            buyerTradeState,
          },
          {
            tradeStateBump: buyerTradeState.bump,
            escrowPaymentBump: buyerEscrow.bump,
            buyerPrice: new BN(price.qty.toString()),
            tokenSize: 1,
          }
        ),
        MPLAuctionHouse.createExecuteSaleInstruction(
          {
            buyer,
            seller,
            tokenAccount: nft.address,
            tokenMint: nft.mint,
            metadata: nft.metadata,
            escrowPaymentAccount: buyerEscrow,
            sellerPaymentReceiptAccount: dstPaymentAccount,
            buyerReceiptTokenAccount: nft.toAddress,
            authority: auctionHouse.authority,
            treasuryMint: auctionHouse.treasuryMint,
            auctionHouse: auctionHouse.address,
            auctionHouseFeeAccount: auctionHouse.feeAccount,
            auctionHouseTreasury: auctionHouse.treasury,
            buyerTradeState,
            sellerTradeState,
            freeTradeState: freeSellerTradeState,
            programAsSigner: PDA.PROGRAM_AS_SIGNER,
            anchorRemainingAccounts: creators,
          },
          {
            escrowPaymentBump: buyerEscrow.bump,
            freeTradeStateBump: freeSellerTradeState.bump,
            programAsSignerBump: PDA.PROGRAM_AS_SIGNER.bump,
            buyerPrice: new BN(price.qty.toString()),
            tokenSize: 1,
          }
        ),
      ],
    };
  }
}
