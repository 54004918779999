import { useEffect, useState } from "react";
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'
import {
  setConfig,
  Wallet,
  KeypairIdentity,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  MintAccount,
  MetadataAccount,
  SOL,
  PDA
} from "@captainxyz/solana-core";

import * as SPLToken from "@solana/spl-token";
import "./Test.css"

import {
  AuctionHouseAccount
} from "@captainxyz/marketplace";

const {
  Connection,
  PublicKey,
  Keypair,
} = require('@solana/web3.js')

const { decode } = require('bs58')



require('@solana/wallet-adapter-react-ui/styles.css');


const Test = props => {
  const [nfts, setNFTs] = useState([])
  const [prices, setPrices] = useState({})
  const [loading, setLoading] = useState(null)
  const adapter = useWallet()

  useEffect(() => {
    //getNFTs()
  }, []);

  useEffect(() => {
    if(adapter.publicKey){
      //getTokenAccounts()
      getNFTs()
    }   
  }, [adapter]);

  const getNFTs = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/stagehand/auctionhouse/listings`
    let headers = { 
     "Content-Type": "application/json"
    }   
    let resp = await fetch(url, {
      method: "get",
      headers: headers
    })  
    resp = await resp.json()
    let nfts = resp.nfts

    setConfig(
      'mainnet-beta', 
      { rpcEndpoint: 'https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb'} 
    )

    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    const operator = new Operator("mainnet-beta", walletAdapterIdentity);

    let accounts = []
    let prices = {}
    
    for(let i=0; i<nfts.length; i++){
      console.log(i);
      console.log(nfts[i].mint)
      try{

        let mintAccount = await MintAccount.init(operator, 
          new PublicKey(nfts[i].mint)) 
        accounts.push(mintAccount)
        prices[nfts[i].mint] = nfts[i].price
      } catch (err){
        console.log(err)
      }
    }
    setPrices(prices)
    setNFTs(accounts)
  }

  const recordBought = async (address) => {
    let url = `${process.env.REACT_APP_HNGR_API}/stagehand/bought`
    let headers = { 
     "Content-Type": "application/json"
    }   
    let params = {mint:address, sold_to:adapter.publicKey.toString()}
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params)
    })  
  }

  const buy = async (address) => {
    let price = prices[address]
    alert(price)
    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    const operator = new Operator("mainnet-beta", walletAdapterIdentity);
    const sellerAuctionhouseAddress = new PublicKey("DwBEkZ8SEmKrDzVvj1xi5s7tG5K1ZjPtVVavjasWKdGZ")

    setConfig(
      'mainnet-beta', 
      { rpcEndpoint: 'https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb'} 
    )

    let mint = new PublicKey(address)
    let tokenAddress = PDA.token(
      mint,
      new PublicKey("FqyiygsWRLc2jsMWNdME8JCYyizcC4KEa6y18AmW4JQb")
    )   
    let sellerNFT = await TokenAccount.init(operator, tokenAddress)
    const buyerAH = await AuctionHouseAccount.init(operator, sellerAuctionhouseAddress);

    const clubs = new PublicKey("6c9b73kDgkoBsQKcPVPXewdytbUqUKSQcAWQfff16wr7")
    let clubsAddress = PDA.token(
      clubs,
      new PublicKey("FqyiygsWRLc2jsMWNdME8JCYyizcC4KEa6y18AmW4JQb")
    )

    let clubsAccount = await TokenAccount.init(operator, clubsAddress)
    let clubsCurrency = clubsAccount.metadata.currency
    price = clubsCurrency.amountFromValue(price);


    setLoading(true)
    try{
      const [buyerNFT, result] = await buyerAH.buyNFT(sellerNFT, price);
      setLoading(false)
      if(result.signature){
        recordBought(address)
        alert('Bought succesfully!')
      }
    } catch (err) {
      alert('Buying may have failed')
      console.log(err)
      setLoading(false)
    }
  }


  return (
    <div>
      {loading && (
        <div id='loading'>
          <img src='https://cdn.hngr.co/tamperproof/landingspinner.gif' />
        </div>
      )}
      <h3> Welcome to Bacon Wrapped Demo! </h3>
      <WalletMultiButton />
      <div className='nfts'>
        {nfts.map(nft => (
          <NFT
            nft={nft}
            buy={()=>buy(nft.address.toString())}
            key={nft.mint.toString()}
          />
        ))}
      </div>
    </div>
  )
}

const NFT = ({nft, buy}) => (
  <div className='nft-container'>
    <h5>{nft.metadata._json.name}</h5>
    <br/><br/>
    <img
      className='nft-image'
      src={nft.metadata._json.image}
    />
    <button onClick={buy}>Buy </button>
    <a
      href={`https://solscan.io/token/${nft.address.toString()}`}
      target='_blank'
    >
      Solscan
    </a>
 
  </div>
)


export default Test 
